import React from 'react';
import ReactMarkdown from 'react-markdown';
import { graphql } from 'gatsby';
import Layout from '../../../components/layout';
import ContactDiv from "../../../components/contactUs"
import HeadHelmet from '../../../components/head';
import StyledCareers from './style';
import { StyledBackground } from '../../../components/ui-elements/backgroundImage';

const Careers = ({ data }) => {
    const { MetaData, title, sectionUnderHero, hero_image_careers_page } = data?.strapiSsCareers || {};
    return (
        <>
            {MetaData ? <HeadHelmet data={MetaData} /> : null}
            <Layout>
                <StyledCareers>
                  <StyledBackground
                    img={hero_image_careers_page.childImageSharp.fluid}
                    title={title}
                    titleWidth="100%"
                  />
                    <ReactMarkdown className={'section-under-hero'}>
                        {sectionUnderHero}
                    </ReactMarkdown>
                  <div className={'apply-now-button-wrapper'}>
                    <a href={'mailto:info@stanfordsonoma.com'} className={'Services__blueButton'} >
                      APPLY NOW
                    </a>
                  </div>

                </StyledCareers>
                <ContactDiv />
            </Layout>
        </>
    );

};
export default Careers;

export const careersPageQuery = graphql`
query {
    strapiSsCareers {
       MetaData {
        title
        metaKeywords
        metaDescription
      }
      title
      sectionUnderHero
       hero_image_careers_page {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid
          }
          }
          }
    }
  }
`;
